"use client";

import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import NextAppDirEmotionCacheProvider from "./EmotionCache";
import { ThemeProvider } from "@mui/material/styles";
import { Box, GlobalStyles } from "@mui/material";
import { getTheme } from "@/themes/presets";

export default function ThemeRegistry({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = getTheme();
  const backgroundImage = theme.backgroundImage?.body;
  const backgroundSize = theme.backgroundImage?.bodySize;
  return (
    <NextAppDirEmotionCacheProvider options={{ key: "mui" }}>
      <GlobalStyles
        styles={{
          body: {
            ...(backgroundImage && {
              backgroundColor: "transparent",
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: backgroundSize || "contain",
              backgroundPosition: "fixed",
              position: "relative",
            }),
          },
          "*": {
            msOverflowStyle: "none",
            // scrollbarWidth: "thin",
          },
          '&::-webkit-scrollbar': {
            width: '0.1em',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main,
            borderRadius: '0.25em', 
          },
        }}
      />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
        {theme.backgroundImage.bodyOpacity && (
          <Box
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: -1,
              width: "100%",
              height: "100%",
              backgroundColor: `rgba(0, 0, 0, ${theme.backgroundImage.bodyOpacity})`,
            }}
          />
        )}
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
}
